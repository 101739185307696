
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'ImplementerWorkExamples',
})
export default class ImplementerWorkExamples extends Vue {
  @Prop()
  readonly oPropData: any
}
